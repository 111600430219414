@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'openSans-light';
  src: local('openSans-light'), url(./fonts/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'openSans-extra-bold';
  src: local('openSans-extra-bold'), url(./fonts/OpenSans-ExtraBold.ttf) format('truetype');
}

.text-base-color {
  @apply text-gray-600 dark:text-gray-400;
}

.bg-base-color {
  @apply bg-white dark:bg-gray-800;
}

.title-base-color {
  @apply text-black dark:text-white;
}

.text-secondary-color {
  @apply text-gray-500 dark:text-gray-400;
}

.btn-green {
  @apply bg-del-light-theme-green hover:bg-del-green text-white rounded-md dark:bg-del-dark-theme-dark-green dark:hover:bg-del-green;
}

.hover-btn-del-green {
  @apply hover:text-del-light-theme-green dark:hover:text-del-green;
}

.icon-del-green {
  @apply text-del-light-theme-green dark:text-del-green;
}

.link-icon {
  @apply text-gray-700 dark:text-white;
}

.input-chatai-color {
  @apply bg-gray-300 dark:bg-gray-800 text-gray-800 dark:text-gray-200 py-3 px-4 rounded-full placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-del-green dark:focus:ring-del-light-theme-green;
}

.keyword-chip-color {
  @apply bg-del-light-theme-green dark:bg-del-dark-theme-dark-green text-white text-xs mr-2 px-2 py-0.5 rounded;
}

.tag-chip-color {
  @apply bg-custom-blue dark:bg-custom-light-teal hover:bg-custom-teal dark:hover:bg-custom-light-blue text-white mr-2 text-xs px-2 py-0.5 rounded;
}

.admin-panel-btn {
  @apply bg-del-light-theme-green dark:bg-del-dark-theme-dark-green hover:dark:bg-del-dark-theme-dark-green hover:bg-del-green dark:hover:bg-del-green text-white mr-2 rounded;
}

.header-icon {
  @apply border border-gray-200 dark:border-gray-600 hover:border-gray-800 hover:dark:border-white;
}

.filter-transition {
  @apply transition-all duration-300 ease-in-out;
}

.filter-transition:not(.no-overflow) {
  overflow: hidden;
}

.bg-modal-window {
  @apply bg-white dark:bg-[#1f2937];
}

body {
  background-color: rgb(236, 236, 236);
}

.dark body {
  background-color: rgb(17, 24, 39);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #a0a0a0 ;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #808080 ;
}

.line-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;
}

.line-loader {
  background-color: #86bc25;
  width: 20%;
  height: 3px;
  border-radius: 15px;
  animation: left-to-right 1s linear forwards infinite;
}
@keyframes left-to-right {
  100% {
    transform: translateX(400%)
  }
}