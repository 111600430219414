.markdownBlock p,
.markdownBlock a,
.markdownBlock h1,
.markdownBlock h2,
.markdownBlock h3,
.markdownBlock h4,
.markdownBlock h5,
.markdownBlock h6,
.markdownBlock ul:not(:has(input)),
.markdownBlock ol,
.markdownBlock blockquote {
    all: revert;
    margin: 0;
}

.markdownBlock {
    strong, p {
        margin: 0;
        padding : 0;
    }
    ul li:has(input[checked]) {
        text-decoration: line-through;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #cbd5e0;
        text-align: center;
    }

    th {
        border: 1px solid #cbd5e0;
        padding: 0.5rem 1rem;
    }

    td {
        border: 1px solid #cbd5e0;
        padding: 0.5rem 1rem;
    }

    blockquote {
        margin-left: 60px;
    }

    code {
        white-space: pre-wrap;
        word-break: break-word;
    }
}

.markdown-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}