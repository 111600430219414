.range-datepicker {
  width: calc(100% - 40px);
}

.custom-calendar .react-datepicker__header {
  background-color: #f8fafb;
  border-bottom: none;
}

.custom-calendar .react-datepicker__day-name,
.custom-calendar .react-datepicker__day,
.custom-calendar .react-datepicker__time-name {
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-weight: 400;
  margin: 0.166rem;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #acacac;
}

.custom-calendar .react-datepicker__day--selected,
.custom-calendar .react-datepicker__day--keyboard-selected {
  background-color: #4b830d;
  color: #fff;
}
.custom-calendar .react-datepicker__day--in-selecting-range {
  background-color: #86bc25;
  color: white;
}
.custom-calendar .react-datepicker__day--selecting-range-start,
.custom-calendar .react-datepicker__day--selecting-range-end {
  background-color: #4b830d;
}

.custom-calendar .react-datepicker__day:hover {
  background-color: #acacac;
}

.custom-calendar .react-datepicker__day--in-range {
  background-color: #86bc25;
}
.custom-calendar .react-datepicker__day--in-range:hover {
  background-color: #4b830d;
}

.custom-calendar .react-datepicker__day--range-start,
.custom-calendar .react-datepicker__day--range-end {
  background-color: #4b830d;
}

.dark .custom-calendar {
  .react-datepicker__current-month {
    color: white;
  } .react-datepicker__header {
    background-color: #3a3a3a;
    border-bottom: none;
  }
  .react-datepicker__month-container {
    background-color: #2d2d2d;
    border-bottom: none;
  }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #c4c4c4;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #575757;
}
}