.editor {
    padding-top: 0;
}

.editor p,
.editor a,
.editor h1,
.editor h2,
.editor h3,
.editor h4,
.editor h5,
.editor h6,
.editor ul,
.editor ol,
.editor blockquote {
    all: revert;
}
.editor blockquote {
    margin-left: 60px;
}